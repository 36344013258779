<template>
    <div>
      <Breadcrumbs main="" title="Activity" />
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
                <b-row class="mt-2">
                <b-col class="col-md-3">
                    <b-row class="col-md-12">
                        <b-col>
                            Select Date From
                        </b-col>
                    </b-row>
                    <b-row class="col-md-12">
                        <b-col>
                            <b-form-input size="sm" type="datetime-local" v-model="input.sdate"></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-3">
                    <b-row class="col-md-12">
                        <b-col>
                            Select Date to
                        </b-col>
                    </b-row>
                    <b-row class="col-md-12">
                        <b-col>
                            <b-form-input size="sm" type="datetime-local" v-model="input.edate"></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-6">
                    <b-row class="col-md-12">
                        <b-col>
                            Select User : 
                        </b-col>
                    </b-row>
                    <b-row class="col-md-12">
                        <b-col>
                            <b-form-select name="type" size="sm" v-model="input.useremail" :options="user_opt"  required></b-form-select>
                        </b-col>
                        <b-col>
                            <b-button variant="primary" class="text-right" @click="SearchLogs()"> Filter </b-button>
                        </b-col>
                    </b-row>
                </b-col>
                </b-row>
                <b-row>
                <div class="col-md-12 mt-5" v-if="loading">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                    <div class="loader-box">
                      <div class="loader-30"></div>
                    </div>
                </div> 
                </b-row>
                <div v-if="(items.length > 0)">
                  <b-row class="mt-3">
                  <b-col md="6" class="mb-3">
                    <b-input-group>
                    <b-input-group-prepend >
                      <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                    </b-input-group-prepend>
                    <b-form-input
                      class="col-md-5"
                      v-model="filter"
                      placeholder="Type to Search"
                    ></b-form-input>
                  </b-input-group>
                  </b-col>
  
                  <b-col md="6" class="mb-3">
                    <b-form-group
                      label-cols="2"
                      label="Per page"
                      class="mb-0 datatable-select"
                    >
                      <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                        @change="filterLogs"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                
                <div class="table-responsive datatable-vue text-left">
                  <b-table
                    show-empty
                    stacked="md"
                    :items="items"
                    :filter="filter"
                    @filtered="onFiltered"
                    :per-page="perPage"
                  >
                  <template #table-caption>Activity List.</template>

                  </b-table>
                </div>
                </div>
                <div v-else>
                    <b-row v-if="(loading != true)" class="mt-5">
                        <b-col class="text-center">
                            No data Found
                        </b-col>
                    </b-row>
                </div>
                <b-col md="6" class="mt-3 p-0">
                  <div class="pagination">
                    <a v-show="currentPage != 1" @click="filterLogs('prev')">&laquo; Back</a>
                    <a class="active">{{currentPage}}</a>
                    <a v-show="items.length >= 1" @click="filterLogs('next')">Next &raquo;</a>
                  </div>
                </b-col>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import UserController from "../../../services/userController"
  import ManagerController from "../../../services/managerController"
  import moment from "moment";
  export default {
      name:'Users',
      data(){
          return{
              input:{useremail:null},
              user_opt:[{"value":null,"text":'select user'}],
              plans:[],
              items:[],
              totalRows:0,
              filter:null,
              currentPage: 1,
              perPage: 10,
              pageOptions: [5, 10, 50],
              loading:false,
              tablefields: [ 'index',
                            
                          ],
              userinfo :[],
              user_data:[]
          }
      },
      mounted(){
        this.userinfo = JSON.parse(window.localStorage.getItem('userinfo'))
          this.getUsers()
      },
      methods: {
          async getUsers(){
              this.user_data = []
              let response = await UserController.getAllUsers()
              if (response.result) {
                  this.user_data = response.message.filter(t=>{
                      if(t.role != 'manager'){
                          return true;
                      }
                  });
            this.user_data.map((t)=>{
                this.user_opt.push({"value":t._id,"text":t.username})
            })
                  
            }
            else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
          },
          onFiltered(filteredItems) {
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
        },
        changeDateFormate(d) {
        return moment(d).format("D/M/YYYY, HH A");
        },
        SearchLogs(){
            this.currentPage = 1
            this.filterLogs()
        },
        async filterLogs(txt){
            this.loading = true
            if(txt == 'next'){this.currentPage ++}
            if(txt == 'prev'){
                if(this.currentPage != 1){
                this.currentPage --
                }
            }
            this.items = []
            let payload = {
            "currentPage": this.currentPage,"pageSize": this.perPage,
            "filters" : {   
                    "sdate": this.input.sdate,
                    "edate": this.input.edate,
                    "userid": this.input.useremail
                }
            }
            let response = await ManagerController.getActivityLogs(payload)
            if(response.result){
                this.items = response.data
            }
            this.loading = false
        }
      }
  }
  </script>


<style scoped>
.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {background-color: #ddd;cursor: pointer;}
</style>